i {
  font-size: 18px;
}

.ant-list-item-meta-content {
  flex: none !important;
  margin-left: 20px;
}

html {
  scroll-behavior: smooth;
}